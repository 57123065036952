@font-face {
  font-family: 'Poppins-Bold';
  src: url(../public/fonts/Poppins-Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(../public/fonts/Poppins-Regular.ttf);
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(../public/fonts/Poppins-SemiBold.ttf);
  font-style: normal;
}

* {
  font-family: 'Poppins-Regular', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  background-color: #fff;
  overscroll-behavior: contain;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(58, 57, 57, 0.3);
  background-color: #7500e5;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
}

/* The font size is changed due to the Safari browser that 
automatically zooms when trying to type in text areas smaller than 16px */
textarea {
  font-size: 16px;
}

/**
* The BEM naming convention:
* - https://bit.ly/3qs1bvO
* - https://bit.ly/33tGjeq
*/

#container {
  width: 100%;
  padding: 72px 24px 24px 24px;

  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

#page-title {
  margin: 16px 0px;
  font-family: Poppins-Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  text-transform: uppercase;
}

/* Margin */

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

/* Flexbox */

.flex {
  display: flex;
}

.flex--sm-gap {
  column-gap: 8px;
}

.flex--md-gap {
  column-gap: 16px;
}

.flex--lg-gap {
  column-gap: 24px;
}

/* Cards */

.card {
  display: flex;

  background-color: #262824;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;

  font-family: Poppins-SemiBold;
  color: #fff;
  font-style: normal;
}

.card__img-container {
  min-width: 97px;
  min-height: 97px;
}

.card__img {
  max-width: 97px;
  max-height: 97px;
  border-radius: 4px;
}

.card__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.card__title {
  font-weight: 600;
  font-size: 14px;
}

.card__body--mission {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  font-weight: 600;
  font-size: 10px;
}
.card__body--mission > button {
  height: 32px;
  font-size: 10px;
  line-height: 15px;
}

.card__prize--mission {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 70px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;

  color: #ffc043;
}

.card__btn--mission {
}

.card__extra-info {
  display: flex;
  justify-content: space-between;

  font-family: Poppins-Regular;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

/* Modals */

.modal__body {
  margin: 24px 0px;

  font-family: Poppins-Regular;
  color: #262824;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.modal__description {
}

.modal__details {
  margin: auto;
  display: flex;
  width: 260px;
  justify-content: space-between;
}
.modal__details > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal__details > div > p {
  font-size: 10px;
  line-height: 15px;
}

.modal__extra-info {
}
.modal__extra-info > div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.modal__extra-info > div > p {
  margin-left: 8px;
}

/* Navigator */
#navigator {
  font-family: Poppins SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.tutoTextClass {
  color: #388ecc;
}

.tutoTextClass:visited {
  color: #b388ff;
}

/* Missions and challenges-related CSS */
.card--mission {
  border-left: 4px solid #7500e5;
  column-gap: 16px;
  height: 128px;
}

.card--challenge {
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}
.card--challenge > .challenge__title {
  font-family: Poppins-Bold;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;

  margin-bottom: 8px;
}
.card--challenge > .challenge__question {
  text-align: center;

  margin-bottom: 16px;
}
.card--challenge > .challenge__prize {
  display: flex;
  align-items: center;

  font-family: Poppins SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;

  margin-bottom: 16px;
}
.card--challenge > .challenge__prize > img.icon {
  height: 24px;
  margin-left: 8px;
}
.card--challenge > .challenge__prize > .yellow {
  color: #ffc043;
}
.card--challenge > .submission {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  color: #05a357;
}
.card--challenge > .submission > span {
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.card--challenge > .submission > div.status {
  text-align: center;
}

.mission__description {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #fff;
}

.mission__status > span {
  font-family: Poppins SemiBold;
  font-weight: 600;
  text-align: left;
}

/* Challenge Forms */
.challenge-form {
  font-size: 14px;
}

.challenge-form > .challenge-form__title {
  margin-top: 24px;
  font: 'Poppins-SemiBold';
}

.challenge-form > .challenge-form__instructions,
.challenge-form > textarea,
.challenge-form > input,
.challenge-form > button {
  margin-top: 16px;
}

.challenge-form__nps-options {
  display: flex;
  margin-top: 24px;
}

.challenge-form__nps-options input[type='radio'] {
  display: none;
}

.challenge-form__nps-options label {
  display: block;
  background: #ccc;
  width: 24px;
  height: 40px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: 600;
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.challenge-form__nps-options label.active {
  background-color: #7500e5;
  color: #fff;
}

/* Journeys-related CSS */

.journey__title {
  font-family: 'SF Pro Display';
  font-size: 14px;
  color: #fff;
}

.journey__start-indicator-container {
  display: flex;
  justify-content: flex-end;
}

.journey__start-indicator {
  width: 58px;
  height: 20px;
  /* styleName: Tuto / Body Bold; */
  font-family: 'SF Pro Display';
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #7500e5;
  background: #ccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-right: 16px;
  margin-top: -20px;
  z-index: 1;
}

/* JourneyStep-related CSS */

.journey__step-container {
  display: flex;
  height: 150px;
  padding: 16px;
}

.journey__step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  z-index: 1;
  cursor: pointer;
}

.journey__step > div.journey__step-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.journey__step > div.journey__step-body > span.journey__step-text {
  font-family: 'SF Pro Display';
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #7500e5;
}

.journey__step--enabled,
.journey__step--unlocked,
.journey__step--expired,
.journey__step--waiting {
  background: linear-gradient(180deg, #7500e5 0%, #05a357 100%);
}

.journey__step--disabled {
  background: #929898;
}

.journey__step--disabled > div.journey__step-body,
.journey__step--expired > div.journey__step-body {
  background: #ccc;
}

.journey__step--disabled > div.journey__step-body > span.journey__step-text,
.journey__step--expired > div.journey__step-body > span.journey__step-text {
  color: #929898;
}

/* JourneyPath-related CSS */

canvas.journey__path {
  position: absolute;
  margin-top: 16px;
}

/* JourneyGrid-related CSS */

.journey__grid-container {
  display: grid;
  background-image: url(../public/images/journeyBackground.png);
  background-color: #262824;
  border-radius: 4px;
  margin-top: 16px;
}

/* ModalStepDescription-related CSS */

.journey__modal {
  font-family: 'Poppins-SemiBold';
  font-size: 14px;
  margin-top: 26px;
}

.journey__modal-title {
  margin-bottom: 24px;
}

.journey__modal-description-label {
  margin-bottom: 8px;
}

.journey__modal-description {
  font-size: 14px;
}

/* SelfSignUp-related CSS */
.self-sign-up__acceptance-wrapper {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
}
.self-sign-up__acceptance-wrapper > a {
  margin-left: 8px;
  color: white;
}
.self-sign-up__login-wrapper {
  margin-top: 8px;
  color: white;
  font-size: 15px;
}
.self-sign-up__login-wrapper > a {
  color: white;
}

button.google-sso-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  padding: 0px;
  font-size: 14px;
  font-family: Poppins-Regular;
  cursor: pointer;
}

button.google-sso-btn:hover {
  opacity: 0.9;
}

.heart-beat {
  animation: beating 0.8s ease-in-out infinite alternate;
  box-shadow: 0 0 20px #fff;
}

@keyframes beating {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.6);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes stretch {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.presentation-play-stretch {
  animation: stretch 0.5s ease forwards;
}
